<template>
    <div class="Revenue" :class="{ 'is-loading': isFetchingData }">
        <Headline :title="revenueHeadline" :highlight="shiftsIncome">
            <p style="font-size:1rem">
                Vergleich zum Vormonat ({{ timePeriod }}):
                <b>{{ lastMonthIncome }}</b>
            </p>
        </Headline>

        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="drivers.filter(d => d.isVisible)"
                    :selected="selectedEmployee"
                    placeholder="Fahrer"
                    @onItemSelect="handleEmployeeSelect"
                />
                <Dropdown
                    hasSearch
                    :items="cars"
                    :selected="selectedCar"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />
                <Datepicker monthPicker @onChange="handleDateChange" :startDate="selectedFrom" />
                <Button @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <!-- <Download
                    :title="isMobile ? 'CSV' : 'Download CSV'"
                    type="csv"
                    @onDownload="handleDownload"
                /> -->
                <Download
                    :title="isMobile ? 'PDF' : 'Download PDF'"
                    type="pdf"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar>
        <ChartV2
            v-if="chartData.length"
            halfHeight
            type="bar"
            :title="accumulatedRevenue"
            :values="chartData"
            :activeBar="activeBar"
            @onLeave="activeRow = null"
        />

        <div class="Revenue-Tables" v-if="shifts.length">
            <revenue-table
                :shifts="revenueByEmployee"
                title="Umsatz nach Fahrer"
                @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
            />
            <revenue-table
                :shifts="revenueByCar"
                title="Umsatz nach Fahrzeug"
                @onRowClick="props => handleRowClick({ ...props, type: 'car' })"
            />
        </div>
        <EmptyState v-else />
    </div>
</template>

<script>
import axios from 'axios';
import {
    startOfDay,
    endOfDay,
    subDays,
    format,
    startOfMonth,
    formatISO,
    subMonths,
    addDays,
    endOfMonth,
    isSameDay,
} from 'date-fns';
import { de } from 'date-fns/locale';
import { euro, priceToEuroString } from '@/lib/helper';

import ChartV2 from '@/components/widgets/ChartV2';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Button from '@/components/widgets/Button';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
// import Row from '@/components/Row';

import { EventBus } from '@/event-bus.js';
import RevenueTable from './components/RevenueTable.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'Revenue',
    components: {
        ChartV2,
        Datepicker,
        Download,
        Button,
        Dropdown,
        Headline,
        Toolbar,
        RevenueTable,
        EmptyState,
    },
    data: () => {
        return {
            lastMonthIncome: '0 €',
            timePeriod: '',
            selectedCar: null,
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfDay(new Date()),
            isFetchingData: false,
            shifts: [],
            revenueByCar: {},
            revenueByEmployee: {},
            chartData: [],
            shiftsIncome: '0 €',
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            cars: JSON.parse(localStorage.getItem('cars')),
            activeRow: -1,
            activeBar: -1,
        };
    },
    created() {
        EventBus.$emit('updateChartStatus', {
            isActive: true,
            index: -1,
        });
    },
    computed: {
        revenueHeadline() {
            return `Ihre Einnahmen für ${format(this.selectedFrom, 'MMMM yyyy', {
                locale: de,
            })}:
        `;
        },
        accumulatedRevenue() {
            const sum = this.chartData.reduce((a, v) => a + Number(v.value), 0);
            const selectedCar = this.selectedCar || { value: '' };
            const selectedEmployee = this.selectedEmployee || { value: '' };
            const additionalText = selectedCar.value || selectedEmployee.value;
            const text = `<span style="font-size:1.5rem">${
                additionalText ? ` - (${additionalText})` : ''
            }</span>`;
            return `Umsatz Gesamt: ${euro(sum).format()}€` + text;
        },
        isMobile() {
            return window.innerWidth < 500;
        },
    },
    methods: {
        format,
        handleRowClick({ id, type }) {
            if (type === 'employee') {
                const driverName = this.drivers.find(d => d.id === id).value;
                this.setActiveRow(this.revenueByEmployee[driverName]);
            } else {
                this.setActiveRow(this.revenueByCar[id]);
            }
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        setActiveRow(item) {
            const driver = this.drivers.find(driver => driver.id === item.id) || {};
            const car = this.cars.find(car => car.id === item.id) || {};
            // reset all
            this.revenueByEmployee = Object.keys(this.revenueByEmployee).reduce((acc, key) => {
                acc[key] = {
                    ...this.revenueByEmployee[key],
                    isActive: false,
                };
                return acc;
            }, {});

            this.revenueByCar = Object.keys(this.revenueByCar).reduce((acc, key) => {
                acc[key] = {
                    ...this.revenueByCar[key],
                    isActive: false,
                };
                return acc;
            }, {});

            if (driver.value) {
                this.revenueByEmployee[driver.value] = {
                    ...this.revenueByEmployee[driver.value],
                    isActive: true,
                };
                this.handleEmployeeSelect({ item: driver });
                this.handleQuery();
            }
            if (car.value) {
                this.revenueByCar[car.value] = {
                    ...this.revenueByCar[car.value],
                    isActive: true,
                };
                this.handleCarSelect({ item: car });
                this.handleQuery();
            }
        },

        async handleQuery() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel`;
                const urlShifts = `${url}/shifts`;
                const resultShifts = axios.post(urlShifts, queryData, {
                    withCredentials: true,
                });
                const urlIncome = `${url}/shifts/income`;
                const resultIncome = axios.post(urlIncome, queryData, {
                    withCredentials: true,
                });

                const previousMonth = startOfDay(subMonths(this.selectedFrom, 1));
                const isSelectedToLastDayOfMonth = isSameDay(
                    this.selectedTo,
                    endOfMonth(this.selectedTo),
                );

                const previousMonthDay = isSelectedToLastDayOfMonth
                    ? endOfMonth(previousMonth)
                    : endOfDay(subMonths(this.selectedTo, 1));

                const resultIncomePrevMonth = axios.post(
                    urlIncome,
                    {
                        ...queryData,
                        rangeStartAt: formatISO(previousMonth),
                        rangeEndAt: formatISO(previousMonthDay),
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.timePeriod = `${format(previousMonth, 'dd.MM.yy', {
                    locale: de,
                })} - ${format(previousMonthDay, 'dd.MM.yy', {
                    locale: de,
                })}`;

                const urlDaily = `${url}/shifts/income/daily`;
                const resultDaily = axios.post(
                    urlDaily,
                    {
                        ...queryData,
                        rangeEndAt: formatISO(endOfMonth(this.selectedFrom || new Date())),
                    },
                    {
                        withCredentials: true,
                    },
                );

                const [shifts, income, incomePrevMonth, daily] = await Promise.all([
                    resultShifts,
                    resultIncome,
                    resultIncomePrevMonth,
                    resultDaily,
                ]);
                this.lastMonthIncome = priceToEuroString(incomePrevMonth.data?.income);
                this.shiftsIncome = priceToEuroString(income.data?.income);
                this.shifts = shifts.data;

                this.revenueByEmployee = shifts.data.reduce((acc, shift) => {
                    const driverName = shift.driver.name;
                    if (!acc[driverName]) {
                        acc[driverName] = {
                            id: shift.driverNumber,
                            name: driverName,
                            shifts: [],
                            income: 0,
                            timePeriod: {
                                start: new Date(shift.startAt),
                                end: new Date(shift.endAt),
                            },
                        };
                    }
                    acc[driverName].shifts.push(shift);
                    acc[driverName].income += shift.finalTotalAmount;
                    acc[driverName].timePeriod.start = new Date(
                        Math.min(
                            new Date(acc[driverName].timePeriod.start).getTime(),
                            new Date(shift.startAt).getTime(),
                        ),
                    );
                    acc[driverName].timePeriod.end = new Date(
                        Math.max(
                            new Date(acc[driverName].timePeriod.end).getTime(),
                            new Date(shift.endAt).getTime(),
                        ),
                    );
                    return acc;
                }, {});

                this.revenueByCar = shifts.data.reduce((acc, shift) => {
                    const car = this.cars.find(c => c.id === shift.licenseNumber);
                    const carName = car ? car.value : '';
                    if (!acc[carName]) {
                        acc[carName] = {
                            id: shift.licenseNumber,
                            name: carName,
                            shifts: [],
                            income: 0,
                            timePeriod: {
                                start: new Date(shift.startAt),
                                end: new Date(shift.endAt),
                            },
                        };
                    }
                    acc[carName].shifts.push(shift);
                    acc[carName].income += shift.finalTotalAmount;
                    acc[carName].timePeriod.start = new Date(
                        Math.min(
                            new Date(acc[carName].timePeriod.start).getTime(),
                            new Date(shift.startAt).getTime(),
                        ),
                    );
                    acc[carName].timePeriod.end = new Date(
                        Math.max(
                            new Date(acc[carName].timePeriod.end).getTime(),
                            new Date(shift.endAt).getTime(),
                        ),
                    );
                    return acc;
                }, {});

                const dailyHasValues = daily.data.some(({ income }) => income);
                this.chartData = dailyHasValues
                    ? [...daily.data].map(({ income, date }) => ({
                          formattedValue: euro(income).format(),
                          formatter: value => euro(value).format(),
                          formattedDate: format(new Date(date), 'dd MMM yyyy', {
                              locale: de,
                          }),
                          rawDate: date,
                          value: income,
                          date: format(new Date(date), 'dd.MM', {
                              locale: de,
                          }),
                      }))
                    : [];
                this.$emit('onFinishLoading');
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        setActiveChart(obj) {
            EventBus.$emit('updateChartStatus', obj);
        },
        handleDownload() {
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            const urlShifts = `https://api.app.taxifusion.de/cpanel/shifts/revenue/daily/${format(
                this.selectedFrom,
                'yyyy',
            )}/${format(this.selectedFrom, 'MM')}/driver/${queryData.driverNumber ||
                '0'}/license/${queryData.licenseNumber || '0'}/pdf`;
            window.open(urlShifts, '_blank');
        },
        handleDateChange(date) {
            this.selectedFrom = date.from;
            this.selectedTo = date.to;
        },
    },
    mounted() {
        EventBus.$emit('updateChartStatus', {
            isActive: true,
            index: -1,
        });
        setTimeout(() => {
            this.handleQuery();
        }, 300);
    },
};
</script>

<style lang="scss">
.Revenue {
    @extend %contentWrapper;
    @extend %page;

    .Toolbar {
        margin-bottom: 25px;
    }
    .Revenue-Tables {
        display: flex;
        gap: 10px;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            gap: 80px;
        }
    }
}
</style>
