var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Row-Wrap",
    class: [{
      'is-empty': _vm.mShifts.length === 0
    }]
  }, [_c('h5', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "RevenueTable-Toolbar"
  }, [_c('div', {
    staticClass: "Sort-Options"
  }, _vm._l(_vm.sortOptions, function (sort, i) {
    return _c('span', {
      key: i,
      staticClass: "Sort-Option",
      class: [{
        active: sort.active
      }, {
        asc: sort.direction === 'asc'
      }, {
        desc: sort.direction === 'desc'
      }],
      on: {
        "click": function click($event) {
          return _vm.handleSort(sort);
        }
      }
    }, [_vm._v(" " + _vm._s(sort.label) + " "), _c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "width": "24",
        "height": "24",
        "svg-inline": '',
        "role": 'presentation',
        "focusable": 'false',
        "tabindex": '-1'
      }
    }, [_c('path', {
      attrs: {
        "d": "M12 16l-6-6h12l-6 6z",
        "fill": "currentColor"
      }
    })])]);
  }), 0)]), _vm._l(_vm.mShifts, function (shift, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "parentHover": false,
        "items": shift,
        "index": i,
        "isActive": shift.isActive
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.$emit('onRowClick', shift);
        }
      }
    });
  }), _vm.mShifts.length >= 30 && _vm.mShifts.length < _vm.shifts.length ? _c('Button', {
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v("Mehr Laden")]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }