<template lang="html">
    <div class="Row-Wrap" :class="[{ 'is-empty': mShifts.length === 0 }]">
        <h5>{{ title }}</h5>
        <div class="RevenueTable-Toolbar">
            <div class="Sort-Options">
                <span
                    class="Sort-Option"
                    v-for="(sort, i) in sortOptions"
                    :key="i"
                    :class="[
                        { active: sort.active },
                        { asc: sort.direction === 'asc' },
                        { desc: sort.direction === 'desc' },
                    ]"
                    @click="handleSort(sort)"
                >
                    {{ sort.label }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                </span>
            </div>
        </div>
        <Row
            v-for="(shift, i) in mShifts"
            :parentHover="false"
            :items="shift"
            :key="i"
            :index="i"
            :isActive="shift.isActive"
            @onClick="$emit('onRowClick', shift)"
        />

        <Button
            v-if="mShifts.length >= 30 && mShifts.length < shifts.length"
            @onClick="handleLoadMore"
            >Mehr Laden</Button
        >
    </div>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import Button from '@/components/widgets/Button';
import { format } from 'date-fns';
import { round2d, euro } from '@/lib/helper';

export default {
    name: 'ItemTable',
    components: {
        Row,
        Button,
    },
    props: {
        title: {
            type: String,
        },
        shifts: {
            type: Object,
        },
    },
    data: () => {
        return {
            cursor: 0,
            activeRow: -1,
            activeBar: -1,
            sort: {
                label: 'Umsatz',
                active: true,
                key: 'income',
                direction: 'desc',
            },
            sortOptions: [
                {
                    label: 'Name',
                    active: false,
                    key: 'name',
                    direction: 'asc',
                },
                {
                    label: 'Umsatz',
                    active: true,
                    key: 'income',
                    direction: 'asc',
                },
                {
                    label: 'Datum',
                    active: false,
                    key: 'timePeriod',
                    direction: 'asc',
                },
            ],
        };
    },
    computed: {
        mShifts() {
            const sorted = Object.values(this.shifts).sort((a, b) => {
                if (this.sort.direction === 'asc') {
                    if (this.sort.key === 'timePeriod') {
                        return a.timePeriod.start - b.timePeriod.start;
                    }
                    return a[this.sort.key] - b[this.sort.key];
                } else {
                    if (this.sort.key === 'timePeriod') {
                        return b.timePeriod.start - a.timePeriod.start;
                    }
                    return b[this.sort.key] - a[this.sort.key];
                }
            });
            return this.formatShifts(sorted).slice(0, this.cursor + 80);
        },
    },
    methods: {
        handleSort(sort) {
            this.sortOptions.forEach(s => {
                s.active = false;
            });
            sort.active = true;
            sort.direction = sort.direction === 'asc' ? 'desc' : 'asc';
            this.sortOptions = [...this.sortOptions];
            this.sort = sort;
            this.cursor = 0;
        },
        handleLoadMore() {
            this.cursor += 80;
        },
        formatShifts(shifts) {
            const result = [];
            for (const shift of Object.values(shifts)) {
                result.push({
                    ...shift,
                    row: [
                        { name: 'Fahrer', value: shift?.name || '' },

                        {
                            name: 'Umsatz (€)',
                            value: euro(shift.income).format() + '€',
                        },
                        {
                            name: 'Zeitraum',
                            value:
                                format(new Date(shift.timePeriod.start), 'dd.MM.yyyy') +
                                ' - ' +
                                format(new Date(shift.timePeriod.end), 'dd.MM.yyyy'),
                        },
                    ],
                });
            }
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    > * {
        min-width: 50%;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    h5 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .RevenueTable-Toolbar {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 5px;

        @media (max-width: breakpoint(tabletLandscape)) {
            flex-direction: column;
            align-items: flex-start;
        }
        .Sort-Options {
            gap: 20px;
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
        }
        .Sort-Option {
            display: flex;
            align-items: center;
            flex: 1;
            cursor: pointer;
            font-size: 12px;
            user-select: none;

            &:hover {
                filter: brightness(1.2);
            }
            svg {
                width: 15px;
                height: 15px;
            }
            &.active {
                font-weight: 700;
            }
            &.asc {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .Button {
        display: block;
        margin: 0 auto !important;
    }
}
</style>
